//* #region ----------------------------------------------- Types

import type APP_EVENTS from "../init/events.js"

type T = MU_LoginForm

type Dependencies = {
	events: typeof APP_EVENTS
}

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only utility/helper modules)

import { $, _ } from "haq"

//* #endregion ----------------------------------------------- Module Imports (only utility/helper modules)

export default function makeLoginForm({ events }: Dependencies) {
	let initialized = false

	const Form = _<T["LoginForm"]>("loginForm")
	const SubmitBtn = $<T["SubmitBtn"]>(Form, "[x_selector='submitBtn']")[0]

	return Object.freeze({
		init
	})

	function init() {
		if (initialized) return

		Form.addEventListener("submit", () => {
			events.emit({
				action: "login-form-submitted",
				data: {
					Form,
					SubmitBtn
				}
			})
		})

		initialized = true
	}
}
