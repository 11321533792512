//* #region ----------------------------------------------- Types

import type { AppEventData } from "haq"
import type { GlobalActions } from "./events.js"

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only utility/helper modules)

import RootDoc from "@helpers/rootDoc.js"
import makeAppComponents from "../components/index.js"
import EVENT_ROUTER from "./event-router.js"

//* #endregion ----------------------------------------------- Module Imports (only utility/helper modules)

function makeApp() {
	let APP_COMPONENTS: ReturnType<typeof makeAppComponents>

	_init()

	return Object.freeze({
		resize,
		executeEvent,
		getAppComponents
	})

	function _init() {
		APP_COMPONENTS = makeAppComponents()
		APP_COMPONENTS.loginForm.init()
	}

	function resize() {
		const vh = window.innerHeight / 100
		RootDoc.style.setProperty("--_vh", `${vh}px`)
	}

	function executeEvent<T extends GlobalActions, K extends keyof GlobalActions>(detail: AppEventData<T, K>) {
		const event = EVENT_ROUTER.get(detail.action)
		event.execute(detail.data)
	}

	function getAppComponents() {
		return APP_COMPONENTS
	}
}

const App = makeApp()
export default App
