//* #region ----------------------------------------------- Component Imports

import AppBtnInit from "@WebComponents/AppBtn/AppBtn.js"
import MyInputInit from "@WebComponents/MyInput/MyInput.js"
import PasswordInputInit from "@WebComponents/PasswordInput/PasswordInput.js"
import APP_EVENTS from "../init/events.js"
import makeLoginForm from "./login-form.js"

//* #endregion ----------------------------------------------- Component Imports

export default function makeAppComponents() {
	//* ---------- Custom Web Components -----------------------------------------------

	AppBtnInit()
	MyInputInit()
	PasswordInputInit()

	//* ---------- Component Factories -----------------------------------------------

	const loginForm = makeLoginForm({ events: APP_EVENTS })

	//* ---------- Exposed Factories -----------------------------------------------

	return Object.freeze({
		loginForm
	})
}
